import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { set, useForm } from "react-hook-form";

import * as API from "../../service/api";
import * as APIURL from "../../service/string/apiUrl";
import * as TOA from "../../service/library/toast";

export default function ServiceModalPayment({ click, setClick, pid, payment }) {
  // useParams를 사용하여 URL 파라미터 가져오기
  const { cid, uid } = useParams();
  const [ruid, setRuid] = useState(null);

  const { register, setValue, getValues, watch } = useForm({
    defaultValues: {},
  });
  const [localSelectedPaymentValue, setLocalSelectedPaymentValue] =
    useState("선택");
  const [selectedBankValue, setSelectedBankValue] = useState("");
  const [formattedAmount, setFormattedAmount] = useState("");
  const [formattedCardNum, setFormattedCardNum] = useState("");
  const [formattedExpDate, setFormattedExpDate] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [formattedRegistration, setFormattedRegistration] = useState("");
  const [formattedBirthDate, setFormattedBirthDate] = useState("");
  const [formattedCmsNum, setFormattedCmsNum] = useState("");
  const [formattedAccountNum, setFormattedAccountNum] = useState("");
  const [additionalBankName, setAdditionalBankName] = useState("");
  const [hasShownNumericToast, setHasShownNumericToast] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [hyosungBankName, setHyosungBankName] = useState("");
  const [bankChangedManually, setBankChangedManually] = useState(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");
  const [currentBankOptions, setCurrentBankOptions] = useState([]);
  const [cmsBankName, setCmsBankName] = useState("");
  const [installment, setInstallment] = useState("");
  const [installmentMonths, setInstallmentMonths] = useState("");
  const [formattedApprovalNum, setFormattedApprovalNum] = useState("");
  const [remark, setRemark] = useState("");

  const handleRemarkChange = (e) => {
    setValue("_remark", e.target.value);
  };

  const paymentMethodToBanks = {
    신용카드: ["신한 가맹점", "BC 가맹점", "효성 ( 카드사 )"],
    무통장입금: ["기업", "국민", "농협"],
  };

  //일시불, 할부 핸들러
  const handleInstallmentChange = (event) => {
    setInstallment(event.target.value);
    if (event.target.value === "일시불") {
      setValue("paymentType", "0");
      setInstallmentMonths(0);
      setValue("installmentMonths", "0");
    }
  };

  const handleInstallmentMonthsChange = (event) => {
    setInstallmentMonths(event.target.value);
    setValue("installmentMonths", event.target.value);
  };
  //

  const [paymentDateLabel, setPaymentDateLabel] = useState("결제일");
  const watchedPaymentMethod = watch("_payment");

  useEffect(() => {
    setCurrentPaymentMethod(watchedPaymentMethod);
    const banksForMethod = paymentMethodToBanks[watchedPaymentMethod] || [];
    setCurrentBankOptions(banksForMethod);
    if (banksForMethod.length > 0 && !selectedBankValue) {
      setSelectedBankValue(banksForMethod[0]);
    } else if (!banksForMethod.includes(selectedBankValue)) {
      setSelectedBankValue("");
    }
  }, [watchedPaymentMethod, selectedBankValue]);

  // cms은행명 입력 핸들러
  const handleCmsBankNameChange = (event) => {
    const value = event.target.value.trim();
    const formattedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    setCmsBankName(formattedValue); // Save the formatted bank name as state
  };

  // 금액 변경 핸들러
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setValue("_amount", value);
  };

  // 결제수단 변경 핸들러
  const handleLocalPaymentValueChange = (event) => {
    setValue("_payment", event.target.value);
  };

  // 은행 변경 핸들러
  const handleBankValueChange = (event) => {
    const bankName = event.target.value;
    setSelectedBankValue(bankName);

    // If the selected bank is "효성 ( 카드사 )", allow entering a custom bank name
    if (bankName === "효성 ( 카드사 )") {
        setHyosungBankName(""); // Clear the existing input
    } else {
        setHyosungBankName(""); // Reset if another bank is selected
    }

    setValue("_useBank", bankName);
};

  // 은행 변경 핸들러
  // const handleBankValueChange = (event) => {
  //   const bankName = event.target.value;
  //   // 상태 업데이트 함수를 사용하여 변경된 은행 이름 저장
  //   setAdditionalBankName(bankName);
  // };

  // 날짜 형식을 ISO로 변환
  const formatDateToISO = (date) => {
    return date ? date.split("T")[0] : null;
  };

  // 입력 변경 핸들러 팩토리 함수
  const handleInputChange =
    (
      formatRules,
      maxLength,
      setValueFunc,
      setFormattedValueFunc,
      numericOnly = false,
      validationCheck = null,
      field = null
    ) =>
    (e) => {
      // e.target.value에서 '-' 와 '/' 문자를 제거
      let value = e.target.value.replace(/[-/]/g, "");

      // e.target.value에서 '-' 와 '/' 문자를 제거
      if (numericOnly) {
        const numericRegex = /^[0-9]*$/;
        if (!numericRegex.test(value)) {
          if (field && !numericFieldsToastShown[field]) {
            setNumericFieldsToastShown({
              ...numericFieldsToastShown,
              [field]: true,
            });
          }
          return;
        } else {
          setNumericFieldsToastShown({
            ...numericFieldsToastShown,
            [field]: false,
          });
        }
      }
      // 입력값의 길이가 maxLength를 초과하면 함수를 종료
      if (value.length > maxLength) {
        return;
      }
      setValueFunc(value);

      if (validationCheck && !validationCheck(value)) {
        TOA.servicesUseToast("입력해주세요.", "s");
        return;
      }

      // formattedValue는 포맷규칙을 적용한 최종 문자열
      let formattedValue = "";
      for (let i = 0; i < value.length; i++) {
        // i번째 위치에 삽입할 특수문자가 있다면 formattedValue에 추가
        if (formatRules[i]) {
          // 그 다음, value의 i번째 문자를 formattedValue에 추가
          formattedValue += formatRules[i];
        }
        formattedValue += value[i];
      }
      if (value.length > maxLength) {
        value = value.substring(0, maxLength);
        return;
      }
      console.log("Formatted Value:", formattedValue);
      setFormattedValueFunc(formattedValue);
    };

    const [formData, setFormData] = useState({
      cmsBankName: "",
      cmsNum: "",
      accountNum: "",
      registration: "",
      remark: ""
    });
    
  // useCallback을 이용해 불필요한 렌더링을 최소화
  const handleCardNumChange = useCallback(
    handleInputChange(
      { 4: "-", 8: "-", 12: "-" },
      16,
      (val) => setValue("_cardnum", val),
      setFormattedCardNum,
      true,
      null,
      "_cardnum"
    ),
    []
  );
  const handleExpDateChange = useCallback(
    handleInputChange(
      { 2: "/" },
      4,
      (val) => setValue("_cardValidnum", val),
      setFormattedExpDate,
      true,
      null
    ),
    "_cardValidnum",
    []
  );
  const handleBirthDateChange = useCallback(
    handleInputChange(
      { 4: "/", 6: "/" },
      8,
      (val) => setValue("_birthDate", val),
      setFormattedBirthDate,
      true,
      null,
      "_birthDate"
    ),
    []
  );
  // const handleBusinessNumberChange = (e) => {
  //   const value = e.target.value.replace(/[^0-9-]/g, ""); // Allow numbers and hyphens
  //   setFormattedRegistration(value);
  //   setValue("_registration", value);
  // };
  const handleBusinessNumberChange = useCallback(
    handleInputChange(
      { 3: "-", 5: "-" },
      10,
      (val) => setValue("_registration", val),
      setFormattedRegistration,
      true,
      null,
      "_registration"
    ),
    []
  );
  // const handleCMSNumChange = (e) => {
  //   let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
  //   setFormattedCmsNum(value);
  //   setValue("_cmsNum", value);
  // };
  const handleCMSNumChange = useCallback(
    handleInputChange(
      {},
      8,
      (val) => setValue("_cmsNum", val),
      setFormattedCmsNum,
      true,
      null,
      "_cmsNum"
    ),
    []
  );
  const handleAccountNumChange = useCallback(
    handleInputChange(
      {},
      20,
      (val) => setValue("_accountNum", val),
      setFormattedAccountNum,
      true,
      null,
      "_accountNum"
    ),
    []
  );

  const handlePhoneNumberChange = useCallback(
    (e) => {
      let value = e.target.value.replace(/-/g, "");
      const numericRegex = /^[0-9]*$/;
      if (value.length > 11) {
        return;
      }
      if (!numericRegex.test(value)) {
        if (!hasShownNumericToast) {
          TOA.servicesUseToast("휴대폰 번호를 입력해주세요.", "e");
          setHasShownNumericToast(true);
        }
        return;
      } else {
        setHasShownNumericToast(false);
      }
      if (value.length === 10) {
        value = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      } else if (value.length === 11) {
        value = value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      }
      setFormattedPhoneNumber(value);
      setValue("_telnum", value);
    },
    [hasShownNumericToast]
  );

  const handleKTNumChange = useCallback((e) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // 숫자만 입력 허용
    if (value.length > 11) {
      value = value.slice(0, 11); // 최대 11자리까지 입력 허용
    }

    if (value.length > 7) {
      if (value.startsWith("02")) {
        // 서울 지역번호 '02'인 경우
        if (value.length === 10) {
          value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
        } else {
          value = value.replace(/^(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      } else {
        // 다른 지역번호 (3자리)
        if ((value[3] === "0" || value[3] === "1") && value.length === 10) {
          value = value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        } else if (value.length === 10) {
          // 중간번호가 3자리 시작 (031-962-2312)
          value = value.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        } else {
          value = value.replace(/^(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        }
      }
    } else if (value.length > 4) {
      if (value.startsWith("02")) {
        // 서울 지역번호 '02'인 경우
        value = value.replace(/^(\d{2})(\d{1,4})/, "$1-$2");
      } else {
        value = value.replace(/^(\d{3})(\d{1,3})/, "$1-$2");
      }
    }

    console.log("KT합산청구번호 Value:", value);
    setValue("_ktNum", value); // Form에 값을 설정
  }, []);

  //최초 알림창 register 변수 저장
  //한글
  const [toastShownState, setToastShownState] = useState({
    _name: false,
    _relationship: false,
  });
  //숫자
  const [numericFieldsToastShown, setNumericFieldsToastShown] = useState({
    _birthDate: false,
    _registration: false,
    _accountNum: false,
    _cmsNum: false,
    _cardnum: false,
    _cardValidnum: false,
  });

  // 금액 입력 핸들러 (원 단위로 변환)
  const handleAmountWithWonChange = (e) => {
    let rawValue = e.target.value;
    let numericValue = rawValue.replace(/[^0-9.]/g, "");

    if (!isNaN(parseFloat(numericValue))) {
      let formattedValue = parseFloat(numericValue).toLocaleString();
      setFormattedAmount(formattedValue);
      // console.log("Setting formattedRegistration", formattedValue);
      setValue("_amount", numericValue);
    } else {
      setFormattedAmount("");
      setValue("_amount", "");
    }
  };

  // 사업자번호 입력 핸들러
  const handleBusinessNumChange = useCallback(
    (e) => {
      const { value } = e.target;
      let formattedValue = value.replace(/[^0-9]/g, ""); // 숫자만 입력 허용
      if (watch("_comType") === "법인") {
        // 법인번호는 보통 13자리
        formattedValue = formattedValue.slice(0, 13);
        // 일반적인 법인번호 포맷: 000000-0000000
        if (formattedValue.length > 6) {
          formattedValue = `${formattedValue.slice(
            0,
            6
          )}-${formattedValue.slice(6)}`;
        }
      } else {
        // 사업자번호는 10자리
        formattedValue = formattedValue.slice(0, 10);
        // 일반적인 사업자번호 포맷: 000-00-00000
        if (formattedValue.length > 5) {
          formattedValue = `${formattedValue.slice(
            0,
            3
          )}-${formattedValue.slice(3, 5)}-${formattedValue.slice(5)}`;
        }
      }
      setFormattedRegistration(formattedValue);
      setValue("_registration", formattedValue);
    },
    [watch("_comType")]
  );

  // 한글만 입력 가능한 핸들러
  const handleKoreanOnlyInput = (e, field) => {
    const value = e.target.value;
    const koreanOnlyRegex = /^[ㄱ-ㅎㅏ-ㅣ가-힣\s]*$/;

    // 입력된 값이 한글이라면
    if (koreanOnlyRegex.test(value)) {
      // 해당필드의 값을 설정
      setValue(field, value);

      // TOA메세지가 표시되지 않도록 상태를 업데이트하기.
      setToastShownState({
        ...toastShownState,
        [field]: false,
      });
    } else {
      // 한글이 아닌 문자를 제거한 값을 설정
      const sanitizedValue = value.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣\s]/g, "");
      setValue(field, sanitizedValue);

      // 해당 필드에 대한 TOA 메세지가 아직 표시되지않았다면
      if (!toastShownState[field]) {
        TOA.servicesUseToast("한글로 입력해주세요.", "");

        // TOA메세지가 표시되었다고 상태를 업데이트
        setToastShownState({
          ...toastShownState,
          [field]: true,
        });
      }
    }
  };

  useEffect(() => {
    if (cid) {
      API.servicesPostData(APIURL.urlGetCompany, { cid: cid })
        .then((res) => {
          const IDNUM = res.data.ruid;
          setRuid(IDNUM);
        })
        .catch((error) => {
          console.error("Error fetching company data:", error);
        });
    }
  }, [cid]);

  const validateFields = () => {
    const paymentMethod = getValues("_payment");
    console.log("Payment Method:", paymentMethod);
    const amountedValue = getValues("_amount");
    const paymentDateValue = getValues("_paymentDate");
    const startDateValue = getValues("_startDate");
    const endDateValue = getValues("_endDate");

    //누락된 필드 이름을 저장할 배열
    let missingFields = [];

    // 각 필드에 값이 비어있는지 확인하고
    // 비어있다면 missingFields 배열에 해당 필드의 한글이름을 추가
    if (!paymentMethod || paymentMethod === "선택") {
      missingFields.push("결제수단");
    } else {
      // 결제수단을 선택했을때만 금액 필드를 확인
      if (!amountedValue) {
        missingFields.push("금액");
      }
    }
    if (!paymentDateValue) {
      missingFields.push("결제일");
    }
    if (!startDateValue) {
      missingFields.push("시작일");
    }
    if (!endDateValue) {
      missingFields.push("만료일");
    }

    //누락된 필드가 있으면 TOA 메세지로 알림
    // False를 반환해서 유효성 검사에 실패했음을 출력!
    if (missingFields.length > 0) {
      const missingFieldsStr = missingFields.join(", ");
      TOA.servicesUseToast(`${missingFieldsStr}을 입력해주세요.`, "e");
      return false;
    }

    //모든 필드가 유효하면 true로 반환!
    return true;
  };

  // 기존 데이터가 있는 경우 해당 데이터로 폼을 채우는 함수
  const fnCheckModify = useCallback(() => {
    if (pid) {
      API.servicesPostData(APIURL.urlGetPayment, { pid: pid })
        .then((res) => {
          if (res.status === "success") {
            const paymentData = res.data;

            // console.log("Received _useBank data:", paymentData.useBank);
            setValue("_payment", paymentData.payment);
            setValue("_paymentDate", formatDateToISO(paymentData.paymentDate));
            setValue("_startDate", formatDateToISO(paymentData.startDate));
            setValue("_endDate", formatDateToISO(paymentData.endDate));
            setValue("_name", paymentData.name);
            setValue("_relationship", paymentData.relationship);
            setValue("_cardnum", setFormattedCardNum(res.data.cardnum));
            setValue("_cardValidnum", setFormattedExpDate(res.data.cardValidnum));
            setValue("_birthDate",paymentData.birthDate);
            setValue("_approvalNum", paymentData.approvalNum);
            setValue("_accountNum", setFormattedAccountNum(res.data.accountNum));
            setValue("_useBank", paymentData.useBank);
            setValue("_cmsNum", paymentData.cmsNum);
            setValue("_registration", setFormattedRegistration(res.data.registration));
            setValue("_telnum", setFormattedPhoneNumber(res.data.telnum));
            setValue("_remark", res.data.remark)
            setValue("_comType", paymentData.comType);
            setValue("_amount", paymentData.amount);
            setValue("_ktNum", res.data.ktNum);
            setValue("paymentType", res.data.paymentType);
            setValue("installmentMonths", res.data.installmentMonths);

            if (paymentData.useBank.startsWith("효성 (")) {
              const match = paymentData.useBank.match(/^효성 \((.*?)\)$/);
              if (match && match[1]) {
                  setSelectedBankValue("효성 ( 카드사 )");
                  setHyosungBankName(match[1]); 
              }
          } else {
              setSelectedBankValue(paymentData.useBank); 
              setHyosungBankName("");
          }

            if (paymentData.payment === "신용카드") {
              if (paymentData.paymentType === "0") {
                  setInstallment("일시불");
              } else {
                  setInstallment("할부");
              }
              setInstallmentMonths(paymentData.installmentMonths);
          }
      }
  })
        .catch((error) => {
          console.error("Error fetching payment data:", error);
        });
    }
  }, [pid, setValue]);

  // 컴포넌트 마운트 및 업데이트 시 실행
  // useEffect(() => {
  //   fnCheckModify();
  // }, [pid, fnCheckModify]);

  useEffect(() => {
    if (currentPaymentMethod === "신용카드" && !installment) {
      setInstallment("일시불");
      setInstallmentMonths(0);
    }
  }, [currentPaymentMethod, installment]);


  useEffect(() => {
    if (pid) {
      API.servicesPostData(APIURL.urlGetPayment, { pid: pid })
        .then((res) => {
          if (res.status === "success") {
            const paymentData = res.data;
            
            setFormattedCardNum(paymentData.cardnum);
            setFormattedExpDate(paymentData.cardValidnum);
            setFormattedBirthDate(paymentData.birthDate);
            setFormattedApprovalNum(paymentData.approvalNum);
            setFormattedAccountNum(paymentData.accountNum); 
            setCmsBankName(paymentData.useBank);
            setFormattedCmsNum(paymentData.cmsNum);
            setFormattedRegistration(paymentData.registration);
            setFormattedPhoneNumber(paymentData.telnum);
            setFormattedAmount(parseFloat(paymentData.amount).toLocaleString());
            setInstallment(paymentData.paymentType === "0" ? "일시불" : "할부");
            setInstallmentMonths(paymentData.installmentMonths);
            setRemark(paymentData.remark);

            setValue("_cardnum", paymentData.cardnum);
            setValue("_cardValidnum", paymentData.cardValidnum);
            setValue("_birthDate", paymentData.birthDate);
            setValue("_approvalNum", paymentData.approvalNum);
            setValue("_accountNum", paymentData.accountNum);
            setValue("_useBank", paymentData.useBank);
            setValue("_cmsNum", paymentData.cmsNum);
            setValue("_registration", paymentData.registration);
            setValue("_telnum", paymentData.telnum);
          }
        })
        .catch((error) => {
          console.error("Error fetching payment data:", error);
        });
    }
  }, [pid]);

  useEffect(() => {
    if (click && pid) {
      fnCheckModify();
    }
  }, [click, pid, fnCheckModify]);

  // {
  //   localSelectedPaymentValue === "신용카드" || watch("_payment") === "신용카드"
  //     ? renderPaymentFields(creditCardFields)
  //     : null
  // }

  // 폼 제출 핸들러
  function resetFormFields() {
    setValue("_payment", localSelectedPaymentValue);
    setValue("_paymentDate", "");
    setValue("_startDate", "");
    setValue("_endDate", "");
    setValue("_name", "");
    setValue("_relationship", "");
    setValue("_cardnum", "");
    setValue("_cardValidnum", "");
    setValue("_birthDate", "");
    setValue("_registration", "");
    setValue("_comType", "");
    setValue("_telnum", "");
    setValue("_useBank", "");
    setValue("_amount", "");
    setValue("_cmsNum", "");
    setValue("_accountNum", "");
    setValue("_ktNum", "");
    setValue("_approvalNum", "");
    setInstallment("일시불");
    setInstallmentMonths(0);
    setValue("_remark", "");
  }

  // 폼 제출 핸들러
  const fnSubmit = (e) => {
    e.preventDefault();
    // 선택된 결제수단 가져오기
    if (!validateFields()) {
      return;
    }
    // "_amount" 필드의 값을 가져와서 숫자 형태로 변환
    const amountValue = String(getValues("_amount"));
    const amountLong = Number(amountValue.replace(/,/g, ""));

    // 날짜를 ISO 형식으로 변환하는 함수
    const formatISODate = (date) => {
      return date ? `${date}T17:45:00` : null;
    };

    // 각 날짜 필드의 값을 ISO 형식으로 변환
    const paymentDate = formatISODate(getValues("_paymentDate"));
    const startDate = formatISODate(getValues("_startDate"));
    const endDate = formatISODate(getValues("_endDate"));
    let useBankValue = selectedBankValue;

    if (currentPaymentMethod === "신용카드" && selectedBankValue === "효성 ( 카드사 )" && hyosungBankName) {
      useBankValue = `효성 (${hyosungBankName})`;
  } else if (currentPaymentMethod === "CMS( 자동이체 )") {
      useBankValue = cmsBankName;
  }

    let data = {
      ruid: ruid,
      payment: currentPaymentMethod,
      paymentDate: paymentDate,
      startDate: startDate,
      endDate: endDate,
      amount: amountLong,
      useBank: useBankValue,
      useFlag: 1,
      remark: remark
    };

    if (currentPaymentMethod === "신용카드") {
      data.paymentType = installment === "일시불" ? "0" : "1";
      data.installmentMonths =
        installment === "일시불" ? "0" : getValues("installmentMonths");
    }

    if (!!pid) {
      data.pid = pid;
    }

    // 수정 시 부모 컴포넌트에게 할당 받은 pid를 사용하여 전달
    switch (currentPaymentMethod) {
      case "신용카드":
        Object.assign(data, {
          name: getValues("_name"),
          birthDate: getValues("_birthDate"),
          relationship: getValues("_relationship"),
          cardnum: getValues("_cardnum"),
          cardValidnum: getValues("_cardValidnum"),
          useBank: useBankValue,
          accountNum: getValues("_accountNum"),
          approvalNum: getValues("_approvalNum"),
          paymentType: installment === "일시불" ? "0" : "1",
          remark: getValues("_remark"),
          installmentMonths:
            installment === "일시불" ? "0" : getValues("installmentMonths"),
        });

        break;
      case "무통장입금":
        Object.assign(data, {
          useBank: useBankValue,
          accountNum: getValues("_accountNum"),
          name: getValues("_name"),
          remark: getValues("_remark"),
        });
        break;

      case "CMS( 자동이체 )":
        Object.assign(data, {
          name: getValues("_name"),
          cmsNum: getValues("_cmsNum"),
          accountNum: getValues("_accountNum"),
          birthDate: getValues("_birthDate"),
          registration: getValues("_registration"),
          relationship: getValues("_relationship"),
          useBank: useBankValue,
          remark: getValues("_remark"),
        });
        break;

      case "KT합산청구":
        Object.assign(data, {
          name: getValues("_name"),
          ktNum: getValues("_ktNum"),
          relationship: getValues("_relationship"),
          birthDate: getValues("_birthDate"),
          registration: getValues("_registration"),
          telnum: getValues("_telnum"),
          comType: getValues("_comType"),
          remark: getValues("_remark"),
          // useBank: useBankValue,
        });
        break;

      case "현금":
        Object.assign(data, {
          amount: amountLong,
          remark: getValues("_remark"), 
        });
        break;
    }

    API.servicesPostData(APIURL.urlSetPayment, data)
      .then((res) => {
        setClick(false);
        resetFormFields();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error submitting payment data:", error);
      });
    console.log("Form Data Before Submission:", data);
  };

  function resetFormFields() {
    setValue("_payment", localSelectedPaymentValue);
    setValue("_paymentDate", "");
    setValue("_startDate", "");
    setValue("_endDate", "");
    setValue("_name", "");
    setValue("_relationship", "");
    setValue("_cardnum", "");
    setValue("_cardValidnum", "");
    setValue("_birthDate", "");
    setValue("_registration", "");
    setValue("_comType", "");
    setValue("_telnum", "");
    setValue("_useBank", "");
    setValue("_amount", "");
    setValue("_cmsNum", "");
    setValue("_accountNum", "");
    setValue("_ktNum", "");
    setInstallment("일시불");
    setInstallmentMonths(0);
    setValue("_remark", "");
  }

  // 폼의 각 필드와 설정을 배열로 관리
  const formFields = [
    {
      label: paymentDateLabel,
      type: "date",
      id: "paymentDate",
      register: "_paymentDate",
    },
    { label: "시작일", type: "date", id: "startDate", register: "_startDate" },
    { label: "만료일", type: "date", id: "endDate", register: "_endDate" },
  ];

  const amountField = {
    label: "금액",
    type: "text",
    id: "amount",
    value: formattedAmount,
    onChange: handleAmountWithWonChange,
    register: "_amount",
    extraElement: (
      <span
        className="userSelect"
        style={{ userSelect: "none", marginRight: "50%" }}
      ></span>
    ),
  };

  const remarkField = {
    label: "비고",
    id: "remark",
    value: watch("_remark") || "",
    onChange: handleRemarkChange,
    register: "_remark",
  };

  const radioButtons = (
    <div className="formContentWrap" style={{ width: "100%", height: "25px" }}>
      <div className="comtype" style={{ width: "100%", height: "100%" }}>
        <label>
          <input
            type="radio"
            value="개인"
            {...register("_comType")}
            checked={watch("_comType") === "개인"}
          />
          개인사업자
        </label>
        <label>
          <input
            type="radio"
            value="법인"
            {...register("_comType")}
            checked={watch("_comType") === "법인"}
          />
          법인사업자
        </label>
      </div>
    </div>
  );

  const creditCardFields = [
    {
      label: "카드사",
      type: "select",
      id: "_useBank",
      onChange: handleBankValueChange,
      options: ["신한 가맹점", "BC 가맹점", "효성 ( 카드사 )"],
      conditionalInput: selectedBankValue === "효성 ( 카드사 )",
      placeholder: "카드사를 입력해주세요",
      conditionalValue: hyosungBankName,
      conditionalOnChange: (e) => setHyosungBankName(e.target.value),
      style: { width: "50%", textAlign: 'center'},
    },
    {
      label: "카드소유자",
      type: "text",
      id: "name",
      register: "_name",
      onChange: (e) => handleKoreanOnlyInput(e, "_name"),
    },
    {
      label: "생년월일",
      type: "text",
      id: "birthDate",
      register: "_birthDate",
      value: formattedBirthDate,
      onChange: handleBirthDateChange,
    },
    {
      label: "계약자와의 관계",
      type: "text",
      id: "relationship",
      register: "_relationship",
      onChange: (e) => handleKoreanOnlyInput(e, "_relationship"),
    },
    {
      label: "카드번호",
      type: "text",
      id: "cardnum",
      register: "_cardnum",
      value: formattedCardNum,
      onChange: handleCardNumChange,
    },
    {
      label: "유효기간",
      type: "text",
      id: "cardValidnum",
      register: "_cardValidnum",
      value: formattedExpDate,
      onChange: handleExpDateChange,
      placeholder: "월 / 년",
    },
    {
      label: "할부 정보",
      type: "custom",
      customComponent: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "26px",
          }}
        >
          <label
            style={{
              display: "inline-flex",
              alignItems: "center",
              margin: "0 20px",
            }}
          >
            <input
              type="radio"
              value="일시불"
              checked={installment === "일시불"}
              onChange={handleInstallmentChange}
              style={{ width: "10px", height: "10px", margin: "0 5px" }}
            />{" "}
            일시불
          </label>
          <label style={{ display: "inline-flex", alignItems: "center" }}>
            <input
              type="radio"
              value="할부"
              checked={installment === "할부"}
              onChange={handleInstallmentChange}
              style={{ width: "10px", height: "10px", margin: "0 5px" }}
            />{" "}
            할부
          </label>
          {installment === "할부" && (
            <input
              type="number"
              placeholder="개월 수"
              value={installmentMonths}
              onChange={handleInstallmentMonthsChange}
              style={{ marginLeft: "10px", width: "388px" }}
            />
          )}
        </div>
      ),
    },
    {
      label: "승인번호", // Approval number label
      type: "text",
      id: "accountNum",
      register: "_accountNum",
      value: formattedAccountNum,
      onChange: handleAccountNumChange,
    },
    amountField,
    remarkField
  ];

  // CMS 필드
  const cmsFields = [
    {
      label: "은행",
      type: "text",
      id: "useBank",
      register: "_useBank",
      value: cmsBankName,
      onChange: handleCmsBankNameChange,
      style: { width: "50%" },
    },
    {
      label: "CMS예금주",
      type: "text",
      id: "name",
      register: "_name",
      onChange: (e) => handleKoreanOnlyInput(e, "_name"),
    },
    {
      label: "CMS회원번호",
      type: "text",
      id: "cmsNum",
      register: "_cmsNum",
      value: formattedCmsNum,
      onChange: handleCMSNumChange,
    },
    {
      label: "생년월일",
      type: "text",
      id: "birthDate",
      register: "_birthDate",
      value: formattedBirthDate,
      onChange: handleBirthDateChange,
    },
    {
      label: "계약자와의 관계",
      type: "text",
      id: "relationship",
      register: "_relationship",
      onChange: (e) => handleKoreanOnlyInput(e, "_relationship"),
    },
    {
      label: "계좌번호",
      type: "text",
      id: "accountNum",
      register: "_accountNum",
      value: formattedAccountNum,
      onChange: handleAccountNumChange,
    },
    {
      label: "사업자번호",
      type: "text",
      id: "registration",
      register: "_registration",
      value: formattedRegistration,
      onChange: handleBusinessNumberChange,
    },
    amountField,
    remarkField
  ];

  // KT합산청구 필드
  const ktFields = [
    {
      label: "KT합산청구번호",
      type: "text",
      id: "ktNum",
      register: "_ktNum",
      value: watch("_ktNum"),
      onChange: handleKTNumChange,
    },
    {
      label: "명의자",
      type: "text",
      id: "name",
      register: "_name",
      onChange: (e) => handleKoreanOnlyInput(e, "_name"),
    },
    {
      label: "계약자와의 관계",
      type: "text",
      id: "relationship",
      register: "_relationship",
      onChange: (e) => handleKoreanOnlyInput(e, "_relationship"),
    },
    {
      label: "생년월일",
      type: "text",
      id: "birthDate",
      register: "_birthDate",
      value: formattedBirthDate,
      onChange: handleBirthDateChange,
    },
    {
      label: "사업자구분",
      type: "custom",
      customComponent: radioButtons,
    },
    {
      label: "사업자 번호 / 법인번호",
      type: "text",
      id: "registration",
      register: "_registration",
      value: formattedRegistration,
      onChange: handleBusinessNumChange,
    },
    {
      label: "명의자 휴대폰",
      type: "text",
      id: "telnum",
      register: "_telnum",
      value: formattedPhoneNumber,
      onChange: handlePhoneNumberChange,
    },
    amountField,
    remarkField
  ];

  //무통장입금 필드
  const bankTransferFields = [
    {
      label: "은행",
      type: "select",
      id: "_useBank",
      register: "_useBank",
      onChange: handleBankValueChange,
      options: currentBankOptions,
      style: { width: "50%", textAlign: 'center' },
    },
    {
      label: "계좌번호",
      type: "text",
      id: "accountNum",
      register: "_accountNum",
      value: formattedAccountNum,
      onChange : handleAccountNumChange,
      style: { width: "50%" },
    },
    {
      label: "입금자명",
      type: "text",
      id: "name",
      register: "_name",
      onChange: handleInputChange,
      style: { width: "50%" },
    },
    amountField,
    remarkField
  ];

  useEffect(() => {
    switch (currentPaymentMethod) {
      case "신용카드":
        setPaymentDateLabel("승인일");
        break;
      case "무통장입금":
        setPaymentDateLabel("입금일");
        break;
      case "CMS( 자동이체 )":
        setPaymentDateLabel("출금일");
        break;
      case "KT합산청구":
        setPaymentDateLabel("결제일");
        break;
      case "현금":
        setPaymentDateLabel("입금일");
        break;

      default:
        setPaymentDateLabel("결제일");
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    // 현재 결제 방식에 따른 은행 옵션 설정 로직을 제거
    if (currentPaymentMethod !== "CMS( 자동이체 )") {
      const banksForMethod = paymentMethodToBanks[watchedPaymentMethod] || [];
      setCurrentBankOptions(banksForMethod);
      if (banksForMethod.length > 0 && !selectedBankValue) {
        setSelectedBankValue(banksForMethod[0]);
      } else if (!banksForMethod.includes(selectedBankValue)) {
        setSelectedBankValue("");
      }
    } else {
      // CMS의 경우 은행명 입력 필드를 사용하므로 기본값 설정을 하지 않습니다.
      setSelectedBankValue(""); // CMS 선택 시 기존 은행명 선택 상태를 초기화
    }
  }, [watchedPaymentMethod, selectedBankValue, currentPaymentMethod]);

  const renderPaymentFields = (fields) => {
    return fields.map((field, index) => (
      <div className="formContentWrap" style={{ width: "100%" }} key={index}>
        <div className="blockLabel">
          <span>{field.label}</span>
        </div>
        <div>
          {field.type === "select" ? (
            <select
              {...register(field.id)}
              value={selectedBankValue}
              onChange={field.onChange}
              style={field.style || { width: "50%", margin: "0" }}
            >
              {currentBankOptions.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : field.type === "custom" ? (
            field.customComponent
          ) : field.id === "remark" ? (
            <textarea
              id={field.id}
              {...register(field.register)}
              value={field.value}
              onChange={field.onChange}
              style={{
                width: "100%",
                minHeight: "50px",
                border: "0.0625rem solid rgba(200,200,200,0.6)",
              }}
            />
          ) : (
            <input
              type={field.type}
              id={field.id}
              {...(field.register && register(field.register))}
              value={field.value}
              onChange={field.onChange}
              placeholder={field.placeholder}
            />
          )}
          {field.conditionalInput && (
            <input
              type="text"
              placeholder={field.placeholder}
              value={field.conditionalValue}
              onChange={field.conditionalOnChange}
              style={field.style}
            />
          )}
        </div>
      </div>
    ));
  };

  return (
    click && (
      <div className="modal">
        <div className="modal-content" style={{ maxHeight: "40.25rem" }}>
          <div className="modal-header">
            <h2>결제수단</h2>
          </div>
          <div id="modal-body">
            <div className="formContentWrap" style={{ width: "100%" }}>
              <div className="blockLabel">
                <span>결제수단</span>
              </div>
              <div>
                <select
                  value={getValues("_payment")}
                  onChange={handleLocalPaymentValueChange}
                  id="payment"
                  {...register("_payment")}
                  style={{ width: "100%", textAlign: 'center'}}
                >
                  <option value="선택">선택</option>
                  <option value="신용카드">신용카드</option>
                  <option value="무통장입금">무통장입금</option>
                  <option value="CMS( 자동이체 )">CMS( 자동이체 )</option>
                  <option value="KT합산청구">KT합산청구</option>
                  <option value="현금">현금</option>
                </select>
              </div>
            </div>
            {/* --------------- 필수입력 -------------------*/}
            {formFields.map((field, index) => (
              <div
                className="formContentWrap"
                style={{ width: "100%" }}
                key={index}
              >
                <div className="blockLabel">
                  <span>{field.label}</span>
                </div>
                <div
                  style={
                    field.extraElement
                      ? { display: "flex", alignItems: "center" }
                      : {}
                  }
                >
                  {field.id === "amount" ? (
                    <div className="input-container">
                      <input
                        type={field.type}
                        id={field.id}
                        {...(field.register && register(field.register))}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      {field.extraElement && field.extraElement}
                    </div>
                  ) : (
                    <input
                      type={field.type}
                      id={field.id}
                      {...(field.register && register(field.register))}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                </div>
              </div>
            ))}
            {localSelectedPaymentValue === "신용카드" ||
            watch("_payment") === "신용카드"
              ? renderPaymentFields(creditCardFields)
              : null}
            {localSelectedPaymentValue === "무통장입금" ||
            watch("_payment") === "무통장입금"
              ? renderPaymentFields(bankTransferFields)
              : null}
            {localSelectedPaymentValue === "CMS( 자동이체 )" ||
            watch("_payment") === "CMS( 자동이체 )"
              ? renderPaymentFields(cmsFields)
              : null}
            {localSelectedPaymentValue === "KT합산청구" ||
            watch("_payment") === "KT합산청구"
              ? renderPaymentFields(ktFields)
              : null}
            {localSelectedPaymentValue === "현금" || watch("_payment") === "현금" ? (
              <>
                <div className="formContentWrap" style={{ width: "100%" }}>
                  <div className="blockLabel">
                    <span>{amountField.label}</span>
                  </div>
                  <div>
                    <input
                      type={amountField.type}
                      id={amountField.id}
                      {...register(amountField.register)}
                      value={amountField.value}
                      onChange={amountField.onChange}
                    />
                  </div>
                </div>
                <div className="formContentWrap" style={{ width: "100%" }}>
                  <div className="blockLabel">
                    <span>{remarkField.label}</span>
                  </div>
                  <div>
                  <textarea
                    id={remarkField.id}
                    {...register(remarkField.register)}
                    value={remarkField.value}
                    onChange={remarkField.onChange}
                    style={{
                      width: "100%",
                      minHeight: "50px",
                      border: "0.0625rem solid rgba(200,200,200,0.6)",
                    }}
                  />
                  </div>
                </div>
              </>
            ) : null}
            {/* <div className="formContentWrap" style={{ width: "100%" }}>
              <div className="blockLabel">
                <span>비고</span>
              </div>
              <div>
                <textarea
                  id="remark"
                  {...register("_remark")}
                  onChange={handleRemarkChange}
                  style={{
                    width: "100%",
                    minHeight: "50px",
                    border: "0.0625rem solid rgba(200,200,200,0.6)",
                  }}
                />
              </div>
            </div> */}

          </div>
          <div className="listSearchButtonWrap">
            <button
              onClick={(e) => {
                e.preventDefault();
                setClick(false);
              }}
            >
              {" "}
              취소
            </button>
            <button onClick={fnSubmit} disabled={!isValid}>
              등록
            </button>
          </div>
        </div>
      </div>
    )
  );
}
