// 사업자 회원 관리 > 견적의뢰서 관리 > 공사콕 견적의뢰서 상세 관리 (!!esid)

import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as API from "../../service/api";
import * as APIURL from "../../service/string/apiUrl";

import * as DATA from "../../action/data";
import * as Image from "../../action/image";

import ImageSet from "../services/ServicesImageSetPreview";

export default function ComponentEstimateinfo() {
  // react-hook-form 라이브러리
  const {
    register,
    setValue,
    // getValues,
    watch,
  } = useForm();
  const { prid } = useParams();
  const dispatch = useDispatch();
  const getedData = useSelector((state) => state.data.getedData, shallowEqual);
  const navigate = useNavigate();
  const [residData, setResidData] = useState({});

  // 현재 페이지가 렌더링되자마자 기존에 입력된 값의 여부를 확인한다.
  useEffect(() => {
    // 추가 시 라디오&체크박스 기본 값
    setValue("_reqEstimate", "1");
    setValue("_reqBill", "1");
    setValue("_useFlagEsti", "1");
    setValue("_gongsaTypeEsti", "reser");

    // 해당 esid의 견적의뢰서 가지고 오기
    API.servicesPostData(APIURL.urlGetEstimateInfo, {
      esid: getedData.resid,
    })
      .then((res) => {
        if (res.status === "success") {
          setResidData(res.data);
        }
      })
      .catch((res) => console.log(res));
  }, []);

  useEffect(() => {
    const payload = {
      ...getedData,
      imgs: residData.addImgs,
    };
    dispatch(DATA.serviceGetedData(payload));

    setValue("_fromUid", residData.fromUid || "");
    setValue("_toUid", residData.toUid || "");
    setValue("_reqDetail", residData.reqDetail || "");
    setValue("_reqPrice", residData.reqPrice || "");
    setValue(
      "_reqVisit",
      (residData.reqVisit && residData.reqVisit.slice(0, 10)) || ""
    );
    setValue("_addInfo", residData.addInfo || "");
    setValue("_proDetail", residData.proDetail || "");
    setValue("_proPrice", residData.proPrice || "");
    setValue(
      "_proVisit",
      (residData.proVisit && residData.proVisit.slice(0, 10)) || ""
    );

    setValue(
      "_reqEstimate",
      residData.reqEstimate && residData.reqEstimate.toString()
    );
    setValue("_reqBill", residData.reqBill && residData.reqBill.toString());

    setValue("_canCardEsti", residData.canCard && residData.canCard);
    setValue(
      "_canCashBillEsti",
      residData.canCashBill && residData.canCashBill
    );
    setValue("_useFlagEsti", residData.useFlag && residData.useFlag.toString());
    setValue("_gongsaTypeEsti", residData.gongsaType || "reser");
    setValue("_readFlagEsti", residData.readFlag || "0");
    setValue("_siteAddressEsti", residData.siteAddress);
    console.log(residData);
  }, [residData]);

  return (
    residData.fromUid && (
      <>
        {/* 갼적서 요청 내용  ================================================================ */}
        <fieldset
          id="CompanyDetail_2"
          style={prid && { border: "1px solid rgba(0,0,0,0.2)" }}
        >
          <h3 style={prid && { backgroundColor: "rgba(0,0,0,0.2)" }}>
            견적의뢰서
            <button
              onClick={() => navigate(`/estimateinfo/${getedData.resid}`)}
            >
              견적의뢰서 수정
            </button>
          </h3>

          {/* 사용 플래그  */}
          <div className="formContentWrap">
            <div className="blockLabel">
              <span>회원관리</span>
            </div>
            <div className="formPaddingWrap">
              <input
                className="listSearchRadioInput"
                type="radio"
                checked={watch("_useFlagEsti") == "0"}
                value="0"
                id="DetailUseFlagEsti0"
                {...register("_useFlagEsti")}
                disabled
              />
              <label
                className="listSearchRadioLabel"
                htmlFor="DetailUseFlagEsti0"
              >
                휴면
              </label>

              <input
                className="listSearchRadioInput"
                type="radio"
                checked={watch("_useFlagEsti") == "1"}
                value="1"
                id="DetailUseFlagEsti1"
                {...register("_useFlagEsti")}
                disabled
              />
              <label
                className="listSearchRadioLabel"
                htmlFor="DetailUseFlagEsti1"
              >
                사용
              </label>
            </div>
          </div>

          {/* 공사 타입 */}
          <div className="formContentWrap">
            <label htmlFor="name" className="blockLabel">
              <span>공사 타입</span>
            </label>
            <div className="formPaddingWrap">
              <input
                type="checkbox"
                value="emer"
                id="emer"
                className="listSearchRadioInput"
                disabled
                checked={
                  (watch("_gongsaTypeEsti") &&
                    watch("_gongsaTypeEsti").includes("emer")) ||
                  false
                }
                {...register("_gongsaTypeEsti")}
              />
              <label htmlFor="emer" className="listSearchRadioLabel">
                긴급
              </label>
              <input
                disabled
                type="checkbox"
                value="inday"
                id="inday"
                name="gongsaTypeEsti"
                className="listSearchRadioInput"
                checked={
                  (watch("_gongsaTypeEsti") &&
                    watch("_gongsaTypeEsti").includes("inday")) ||
                  false
                }
                {...register("_gongsaTypeEsti")}
              />
              <label htmlFor="inday" className="listSearchRadioLabel">
                당일
              </label>
              <input
                disabled
                type="checkbox"
                value="reser"
                id="reser"
                name="gongsaTypeEsti"
                className="listSearchRadioInput"
                checked={
                  (watch("_gongsaTypeEsti") &&
                    watch("_gongsaTypeEsti").includes("reser")) ||
                  false
                }
                {...register("_gongsaTypeEsti")}
              />
              <label htmlFor="reser" className="listSearchRadioLabel">
                예약
              </label>

              <input
                disabled
                type="checkbox"
                value=""
                id="reset"
                className="listSearchRadioInput"
                checked={!!watch("_gongsaTypeEsti") ? false : true}
                {...register("_gongsaTypeEsti")}
              />
              <label htmlFor="reset" className="listSearchRadioLabel">
                일반
              </label>
            </div>
          </div>

          {/* 
            <div className="formContentWrap">
              <label htmlFor="fromUid" className=" blockLabel">
                <span>견적 요청</span>
              </label>
              <div>
                <input
                  disabled
                  type="text"
                  id="fromUid"
                  name="_fromUid"
                  placeholder="입력된 견적의뢰서 내용이 없습니다."
                  {...register("_fromUid", {
                    required: "입력되지 않았습니다.",
                  })}
                />
              </div>
            </div>

            <div className="formContentWrap">
              <label htmlFor="toUid" className=" blockLabel">
                <span>견적 수령</span>
              </label>
              <div>
                <input
                  disabled
                  type="text"
                  id="toUid"
                  placeholder="입력된 견적의뢰서 내용이 없습니다."
                  {...register("_toUid", {
                    required: "입력되지 않았습니다.",
                  })}
                />
              </div>
            </div> 
            */}

          <div className="formContentWrap">
            <label htmlFor="reqVisit" className=" blockLabel">
              <span>방문 요청일</span>
            </label>
            <div>
              <input
                disabled
                type="date"
                id="reqVisit"
                {...register("_reqVisit")}
              />
            </div>
          </div>

          <div className="formContentWrap">
            <label htmlFor="siteAddressEsti" className=" blockLabel">
              <span>방문 요청 주소</span>
            </label>
            <div>
              <input
                disabled
                type="text"
                id="siteAddressEsti"
                name="_siteAddressEsti"
                placeholder="입력된 견적의뢰서 내용이 없습니다."
                {...register("_siteAddressEsti")}
              />
            </div>
          </div>

          <div className="formContentWrap" style={{ width: "100%" }}>
            <label htmlFor="reqDetail" className=" blockLabel">
              <span>요청 내역</span>
            </label>
            <div>
              <textarea
                disabled
                type="textara"
                id="reqDetail"
                placeholder="입력된 견적의뢰서 내용이 없습니다."
                {...register("_reqDetail")}
              />
            </div>
          </div>

          <div className="formContentWrap" style={{ width: "100%" }}>
            <label htmlFor="address" className=" blockLabel">
              <span>결제 요청</span>
            </label>
            <ul className="detailContent">
              {/* 세금 계산서 요청 */}

              <li style={{ width: "25%" }}>
                <div>
                  <span>견적서</span>
                  <div className="detailContentInputWrap">
                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_reqEstimate") == "0"}
                      value="0"
                      id="reqEstimate0"
                      disabled
                      {...register("_reqEstimate")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="reqEstimate0"
                    >
                      미발급
                    </label>

                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_reqEstimate") == "1"}
                      value="1"
                      id="reqEstimate1"
                      disabled
                      {...register("_reqEstimate")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="reqEstimate1"
                    >
                      발급
                    </label>
                  </div>
                </div>
              </li>

              <li style={{ width: "25%" }}>
                <div className="detailContentCheck">
                  <span style={{ width: "100px" }}>카드결제</span>
                  <div className="detailContentInputWrap">
                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_canCardEsti") == "0"}
                      value="0"
                      id="canCardEsti0"
                      disabled
                      {...register("_canCardEsti")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="canCardEsti0"
                    >
                      불가
                    </label>

                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_canCardEsti") == "1"}
                      value="1"
                      id="canCardEsti1"
                      disabled
                      {...register("_canCardEsti")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="canCardEsti1"
                    >
                      가능
                    </label>
                  </div>
                </div>
              </li>

              <li style={{ width: "25%" }}>
                <div>
                  <span>세금계산서</span>
                  <div className="detailContentInputWrap">
                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_reqBill") == "0"}
                      value="0"
                      id="DetailReqBill0"
                      disabled
                      {...register("_reqBill")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="DetailReqBill0"
                    >
                      미발급
                    </label>

                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_reqBill") == "1"}
                      value="1"
                      id="DetailReqBill1"
                      disabled
                      {...register("_reqBill")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="DetailReqBill1"
                    >
                      발급
                    </label>
                  </div>
                </div>
              </li>

              <li style={{ width: "25%" }}>
                <div className="detailContentCheck">
                  <span style={{ width: "150px" }}>현금매출영수증</span>
                  <div className="detailContentInputWrap">
                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_canCashBillEsti") == "0"}
                      value="0"
                      id="canCashBillEsti0"
                      disabled
                      {...register("_canCashBillEsti")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="canCashBillEsti0"
                    >
                      미발급
                    </label>

                    <input
                      className="listSearchRadioInput"
                      type="radio"
                      checked={watch("_canCashBillEsti") == "1"}
                      value="1"
                      id="canCashBillEsti1"
                      disabled
                      {...register("_canCashBillEsti")}
                    />
                    <label
                      className="listSearchRadioLabel"
                      htmlFor="canCashBillEsti1"
                    >
                      발급
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="formContentWrap" style={{ width: "100%" }}>
            <label htmlFor="reqPrice" className=" blockLabel">
              <span>공사 희망 금액</span>
            </label>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                id="reqPrice"
                className="textAlineRight"
                disabled
                {...register("_reqPrice")}
                value={
                  (watch("_reqPrice") &&
                    watch("_reqPrice")
                      .replace(/[^0-9]/g, "")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
                  ""
                }
              />
              <span
                style={{
                  width: "70px",
                  textAlign: "center",
                  lineHeight: "30px",
                  fontSize: "14px",
                }}
              >
                만원
              </span>
            </div>
          </div>

          <div className="formContentWrap" style={{ width: "100%" }}>
            <label htmlFor="addInfo" className=" blockLabel">
              <span>추가 정보</span>
            </label>
            <div>
              <textarea
                disabled
                id="addInfo"
                placeholder="입력된 견적의뢰서 내용이 없습니다."
                {...register("_addInfo")}
              />
            </div>
          </div>

          <ImageSet id="imgs" title="참고 이미지" disabled />
        </fieldset>
      </>
    )
  );
}
